var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isProfileLoading())?_c('Splash'):_c('WithSidebar',{scopedSlots:_vm._u([{key:"main",fn:function(ref){
var toggleSidebarLeft = ref.toggleSidebarLeft;
var toggleSidebarRight = ref.toggleSidebarRight;
return [_c('div',{staticClass:"dashboard bg-light text-primary"},[_c('div',{staticClass:"prompt-container"},_vm._l((_vm.incompleteProfiles),function(profile){return _c('Prompt',{key:profile.status + profile.type,attrs:{"profile":profile}})}),1),_c('div',{staticClass:"dashboard__scrollable"},[_c('header',{staticClass:"dashboard__header bg-white"},[_c('CurrentProfileBurger',{attrs:{"dark":""},on:{"click":toggleSidebarLeft}}),_c('img',{staticClass:"logo",style:({ width: '4rem' }),attrs:{"src":require("@/assets/logo_primary.svg")}}),_c('NotificationIcon',{on:{"click":toggleSidebarRight}})],1),(_vm.getMyCurrentProfile().__typename.toLowerCase() === 'fan')?_c('div',{staticClass:"dashboard__main bg-light"},[(_vm.myEvents.length && !!_vm.me.fan)?_c('div',{staticClass:"dashboard__events"},[_c('p',{staticClass:"dashboard__events-heading font-weight-medium"},[_vm._v(" Upcoming events ")]),_c('div',{staticClass:"dashboard__events-container"},_vm._l((_vm.myEvents),function(event){return _c('EventCard',{key:event.id,attrs:{"event":Object.assign({}, event, {fan: _vm.me.fan})}})}),1)]):_vm._e(),_c('div',{staticClass:"dashboard__feed"},[_c('Post',{attrs:{"data":{
                                avatar: require("@/assets/Goose_2.png"),
                                name: 'Gus the Goose',
                                date: new Date(),
                                text:
                                    'Når alt er på plass, vil denne feeden inneholde relevant informasjon for deg basert på dine interesser og hvor du bor. Enn så lenge vil jeg; Gus the Goose komme med noen små oppdateringer fremover, Rock on!',
                                image: require("@/assets/Goose_2.png")
                            }}})],1)]):_c('div',{staticClass:"dashboard__main bg-light"},[_c('div',{staticClass:"dashboard__events-container"},_vm._l((_vm.eventsFromRequests),function(event){return _c('router-link',{key:event.id,attrs:{"to":event.link},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var navigate = ref.navigate;
return [_c('EventCardSmall',{attrs:{"event":event,"primary":event.status === 'COMPLETE'},on:{"click":navigate}})]}}],null,true)})}),1),(!_vm.$apollo.queries.myEventRequests.loading)?_c('p',{style:({ padding: '.5rem' })},[_vm._v(" Open requests ")]):_vm._e(),(_vm.$apollo.queries.myEventRequests.loading)?_c('LoadingEllipsis',{style:({ padding: '.5rem' })},[_vm._v(" Loading content ")]):(_vm.openRequestsByProfileType.length)?_c('div',{staticClass:"request-container"},_vm._l((_vm.openRequestsByProfileType),function(req){return _c('RequestBox',{key:req.id,attrs:{"request":req,"event":req.event,"is-incoming":true}})}),1):_c('div',{staticClass:"request-container"},[_vm._v(" "+_vm._s(_vm.getMyCurrentProfile().name)+" has no requests yet ")])],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }