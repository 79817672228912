<template>
    <router-link v-slot="{ navigate }" :to="{ name: 'request', params: { id: request.id } }">
        <div class="request bg-white" @click="navigate">
            <img v-if="isIncoming" :src="event.fan.avatar" class="request__image avatar-lg" />
            <img v-else :src="requestee.avatar" class="request__image avatar-lg" />
            <div class="request__body">
                <h3 v-if="isIncoming" class="request__title font-weight-medium">
                    {{ event.fan.name }}
                </h3>
                <h3 v-else class="request__title font-weight-medium">
                    {{ requestee.name }}
                </h3>
                <small v-if="isIncoming" class="request__last-updated capitalize">
                    <!-- {{ requesterType }} • {{ updatedAt }} -->
                    {{ event.fan.__typename }} • {{ timeSinceLastUpdate }}
                </small>
                <small v-else class="request__last-updated capitalize">
                    {{ requestee.__typename }} • {{ timeSinceLastUpdate }}
                </small>
            </div>

            <Badge
                v-if="request.requeststatus === 'DECLINED'"
                label="Canceled"
                size="small"
                color="red"
                class="request__status"
            />

            <Badge
                v-else
                :label="request.statusText"
                size="small"
                :color="getColor"
                class="request__status"
            />
        </div>
    </router-link>
</template>

<script>
import Badge from '@/components/Badge'
import { toTimeSinceString } from '@/utils/dateUtils.js'
import { eventRequestStatusToColor } from '@/utils/statusUtils'
export default {
    name: 'RequestBox',
    components: {
        Badge
    },
    props: {
        request: {
            type: Object,
            required: true
        },
        event: {
            type: Object,
            required: true
        },
        isIncoming: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        updatedAt() {
            return toTimeSinceString(new Date(this.request.updatedAt))
        },
        timeSinceLastUpdate() {
            return toTimeSinceString(new Date(this.request.updatedAt))
        },
        requesterType() {
            return this.incoming ? 'Fan' : this.requester.__typename
        },
        getColor() {
            return eventRequestStatusToColor(this.request.status)
        },
        requestee() {
            return this.request.artist || this.request.venue
        }
    }
}
</script>

<style lang="scss">
.request {
    display: grid;
    grid-template-columns: auto 1fr auto;
    column-gap: 1rem;
    align-items: center;
    padding: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
    &__status {
        align-self: start;
    }
}
</style>
