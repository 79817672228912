<template>
    <Splash v-if="isProfileLoading()" />
    <WithSidebar v-else>
        <template #main="{ toggleSidebarLeft, toggleSidebarRight }">
            <div class="dashboard bg-light text-primary">
                <div class="prompt-container">
                    <Prompt
                        v-for="profile in incompleteProfiles"
                        :key="profile.status + profile.type"
                        :profile="profile"
                    />
                </div>
                <div class="dashboard__scrollable">
                    <header class="dashboard__header bg-white">
                        <CurrentProfileBurger dark @click="toggleSidebarLeft" />
                        <img
                            class="logo"
                            src="@/assets/logo_primary.svg"
                            :style="{ width: '4rem' }"
                        />
                        <NotificationIcon @click="toggleSidebarRight" />
                    </header>
                    <div
                        v-if="getMyCurrentProfile().__typename.toLowerCase() === 'fan'"
                        class="dashboard__main bg-light"
                    >
                        <div v-if="myEvents.length && !!me.fan" class="dashboard__events">
                            <p class="dashboard__events-heading font-weight-medium">
                                Upcoming events
                            </p>
                            <div class="dashboard__events-container">
                                <EventCard
                                    v-for="event in myEvents"
                                    :key="event.id"
                                    :event="{ ...event, fan: me.fan }"
                                />
                            </div>
                        </div>
                        <div class="dashboard__feed">
                            <Post
                                :data="{
                                    avatar: require(`@/assets/Goose_2.png`),
                                    name: 'Gus the Goose',
                                    date: new Date(),
                                    text:
                                        'Når alt er på plass, vil denne feeden inneholde relevant informasjon for deg basert på dine interesser og hvor du bor. Enn så lenge vil jeg; Gus the Goose komme med noen små oppdateringer fremover, Rock on!',
                                    image: require(`@/assets/Goose_2.png`)
                                }"
                            />
                            <!-- <Post v-for="post in posts" :key="post.name" :data="post" /> -->
                        </div>
                    </div>
                    <div v-else class="dashboard__main bg-light">
                        <div class="dashboard__events-container">
                            <router-link
                                v-for="event in eventsFromRequests"
                                :key="event.id"
                                v-slot="{ navigate }"
                                :to="event.link"
                            >
                                <EventCardSmall
                                    :event="event"
                                    :primary="event.status === 'COMPLETE'"
                                    @click="navigate"
                                />
                            </router-link>
                        </div>
                        <p
                            v-if="!$apollo.queries.myEventRequests.loading"
                            :style="{ padding: '.5rem' }"
                        >
                            Open requests
                        </p>
                        <LoadingEllipsis
                            v-if="$apollo.queries.myEventRequests.loading"
                            :style="{ padding: '.5rem' }"
                        >
                            Loading content
                        </LoadingEllipsis>
                        <div v-else-if="openRequestsByProfileType.length" class="request-container">
                            <RequestBox
                                v-for="req in openRequestsByProfileType"
                                :key="req.id"
                                :request="req"
                                :event="req.event"
                                :is-incoming="true"
                            />
                        </div>
                        <div v-else class="request-container">
                            {{ getMyCurrentProfile().name }} has no requests yet
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </WithSidebar>
</template>

<script>
import WithSidebar from '@/components/layout/WithSidebar.vue'
import Post from '@/components/Post.vue'
import Prompt from '@/components/Prompt'
import RequestBox from '@/views/events/RequestBox'
import CurrentProfileBurger from '@/components/CurrentProfileBurger'
import EventCard from '@/components/EventCard'
import NotificationIcon from '@/components/NotificationIcon'
import EventCardSmall from '@/components/EventCardSmall'
import { MY_NOTIFICATIONS_COUNT } from '@/graphql/queries/notifications.js'
import { MY_EVENTS } from '@/graphql/queries/event'
import { MY_EVENTREQUESTS } from '@/graphql/queries/eventRequest'
import { ME } from '@/graphql/queries/user.js'

export default {
    name: 'Dashboard',
    components: {
        EventCard,
        WithSidebar,
        NotificationIcon,
        Post,
        Prompt,
        CurrentProfileBurger,
        EventCardSmall,
        RequestBox
    },
    inject: ['isProfileLoading', 'getMyCurrentProfile'],
    apollo: {
        me: {
            query: ME
        },
        myNotificationsCount: {
            query: MY_NOTIFICATIONS_COUNT
        },
        myEvents: {
            query: MY_EVENTS,
            variables: {
                where: {
                    eventStart_gt: new Date().toISOString()
                },
                sort: 'eventStart:ASC'
            }
        },
        myEventRequests: {
            query: MY_EVENTREQUESTS,
            variables: {
                where: {
                    event: {
                        eventStart_gte: new Date().toISOString()
                    }
                }
            },
            update({ myeventRequests }) {
                return myeventRequests.sort(
                    (a, b) => new Date(a.event.eventStart) - new Date(b.event.eventStart)
                )
            },
            fetchPolicy: 'network-only'
        }
        // items: {
        //     // Apollo will store query results in this key in the data properties, so it must match a data property of the same name.
        //     // below is the "initial" query
        //     query: gql`
        //         query userStatus {
        //             userStatus
        //         }
        //     `,
        //     update(data) {
        //         // This function returns our QUERY into the data property "items" when the component is loaded.
        //         return data.myschema_mytable
        //     },
        //     subscribeToMore: {
        //         // below is the subscription query.
        //         document: gql`
        //             subscription UserStatus {
        //                 userStatus
        //             }
        //         `,
        //         updateQuery: (previousResult, { subscriptionData }) => {
        //             console.log(subscriptionData)
        //             return subscriptionData.data // This returns our SUBSCRIPTION into the data property "items" when there is an update
        //         }
        //     }
        // }
    },
    data() {
        return {
            events: [],
            upcomingEvents: [],
            posts: [],
            myEventRequests: []
        }
    },
    computed: {
        openRequestsByProfileType() {
            return this.requestsByProfileType
                .filter((er) => er.status === 'PENDING' || er.status === 'ACCEPTED')
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        },
        requestsByProfileType() {
            const myCurrentProfileId = this.getMyCurrentProfile().id

            return this.myEventRequests.filter((er) => {
                const profile = er.artist || er.venue
                return profile.id === myCurrentProfileId
            })
        },
        eventsFromRequests() {
            return this.requestsByProfileType.map((req) => ({
                ...req.event,
                link: `/request/${req.id}`
            }))
        },
        myFanStatus() {
            return this.me?.fan?.status
        },
        myArtistStatus() {
            return this.me?.artists?.[0]?.status
        },
        myVenueStatus() {
            return this.me?.venues?.[0]?.status
        },
        incompleteProfiles() {
            return [this.me?.fan, this.me?.artists?.[0], this.me?.venues?.[0]]
                .filter((profile) => profile)
                .filter((profile) => profile.status !== 'COMPLETE')
                .map(({ status, __typename }) => ({ status, type: __typename }))
        }
    },
    created() {
        this.posts = [...Array(3).keys()].map(() => ({
            avatar: require(`@/assets/austin.png`),
            name: this.$faker().name.firstName() + ' ' + this.$faker().name.lastName(),
            date: new Date(),
            text: this.$faker().lorem.sentence(),
            image: require(`@/assets/austin.png`)
        }))
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';
.request-container {
    display: grid;
    padding: 0.5rem;
    row-gap: 0.5rem;
}

.image-container {
    margin-top: 3rem;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    img {
        margin-top: 1rem;
        width: 100%;
    }
}
.dashboard {
    height: 100%;
    &__scrollable {
        padding-bottom: 110px;
        overflow: auto;
        height: 100%;
    }

    position: relative;
    &__header {
        height: 90px;
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        align-items: end;
        padding: 2rem 1rem 1rem;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        & > :last-child {
            justify-self: end;
        }
    }

    &__events-heading {
        padding: 0.5rem 0 0 0.5rem;
    }

    &__events-container {
        display: grid;
        grid-auto-flow: column;
        overflow: auto;
        column-gap: 0.5rem;
        padding: 0.5rem;
    }

    &__feed {
        padding: 1rem;
        .post-card + .post-card {
            margin-top: 0.5rem;
        }
    }
}

.prompt-container {
    width: 100%;
    padding: 1rem;
    bottom: 100px;
    position: absolute;
    left: 0;
    z-index: 2;
    display: grid;
    row-gap: 0.25rem;
    > :last-child::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: -4px;
        right: 30px;
        border-bottom: 10px solid transparent;
        border-left: 10px solid transparent;
        border-right: 10px solid $primary;
        border-top: 10px solid $primary;
        z-index: 2;
    }
}

@media only screen and (min-width: 768px) {
    /* For desktop: */

    .dashboard {
        width: 100%;
        height: 100%;
        &__scrollable {
            padding-bottom: 110px;

            overflow: initial;
            height: 100%;
        }
    }
    .dashboard__header {
        display: none;
    }

    .dashboard__feed {
        min-width: 400px;
        max-width: 700px;
        margin: auto;
        // display: grid;
        // justify-content: center;
        // margin-top: 1rem;
        .post-card + .post-card {
            margin-top: 2rem;
        }
    }
    .dashboard__events {
        // max-width: 100%;
        overflow-x: auto;
    }
    .prompt-container {
        position: fixed;
        top: 15%;
        bottom: auto;
        z-index: 1;
        width: 350px;
        right: auto;
        left: 300px;
        > :last-child::before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 16px;
            bottom: auto;
            left: 0;
            border-bottom: 10px solid transparent;
            border-left: 10px solid transparent;
            border-right: 10px solid $primary;
            border-top: 10px solid $primary;
            z-index: 2;
        }
        // visibility: hidden;
        // width: 400px;
        // left: 1rem;
        // top: 25%;
        // bottom: initial;
        // > :last-child::before {
        //     content: '';
        //     position: absolute;
        //     width: 0;
        //     height: 0;
        //     top: 1rem;
        //     left: 0;
        //     border-bottom: 10px solid transparent;
        //     border-left: 10px solid transparent;
        //     border-right: 10px solid $primary;
        //     border-top: 10px solid $primary;
        //     z-index: 2;
        // }
    }
}
</style>
