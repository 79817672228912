<template>
    <div class="event-card bg-white text-primary">
        <div class="event-card__header">
            <img class="event-card__arranger-logo avatar-sm" :src="event.fan.avatar" />
            <p class="event-card__arranger font-weight-medium">
                <ProfileLink :profile="event.fan" />
            </p>
            <img class="event-card__ellipsis" src="@/assets/ellipsis.svg" />
        </div>
        <div class="event-card__aside">
            <img
                v-if="event.image"
                class="event-card__image gb-image--fit-container"
                :src="event.image.url"
            />
            <div v-else class="event-card__placeholder-image-wrapper">
                <img src="@/assets/logo_white.svg" class="placeholder-image" />
            </div>
        </div>
        <div class="event-card__body">
            <h3 class="event-card__title font-weight-medium">
                {{ event.name }}
            </h3>
        </div>
        <div class="event-card__footer">
            <div>
                <div class="event-card__date font-weight-medium">
                    <p>
                        {{ startDateShort }}
                    </p>
                </div>
                <div class="event-card__time font-weight-medium">
                    <p>{{ startTime }}</p>
                </div>
                <!-- <div class="event-card__tickets smaller">
                    {{ Math.floor(Math.random() * 100) }} tickets remaining
                </div> -->
            </div>
            <!-- <Button
                class="event-card__tickets-button"
                label="Tickets"
                primary
                square
                size="small"
            /> -->
        </div>
    </div>
</template>

<script>
import ProfileLink from '@/components/links/ProfileLink'
import { format, differenceInDays } from 'date-fns'
export default {
    name: 'EventCard',
    components: {
        ProfileLink
    },
    props: {
        event: {
            type: Object,
            required: true
        }
    },
    computed: {
        daysUntilStart() {
            return differenceInDays(new Date(this.event.eventStart), new Date())
        },
        startDateShort() {
            return format(new Date(this.event.eventStart), 'dd LLL')
        },
        startDateDay() {
            return format(new Date(this.event.eventStart), 'EEEE')
        },
        startTime() {
            return format(new Date(this.event.eventStart), 'HH:mm')
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';
$padding: 0.75rem;

.placeholder-image {
    width: 150px !important;
}

.event-card {
    width: 280px;
    border-radius: 5px;

    &__header {
        display: grid;
        grid-template-columns: auto minmax(0, 1fr) auto;
        column-gap: $padding;
        align-items: center;
        padding: $padding;
    }

    &__aside {
        // object-fit: cover;
        height: 200px;
        width: 275px;
        background-color: $primary;
    }

    &__placeholder-image-wrapper {
        display: grid;
        place-items: center;
        height: 100%;
    }

    &__image {
        width: 275px;
    }
    &__body {
        padding: 0.5rem $padding 0;
    }

    &__footer {
        display: grid;
        grid-template-columns: 1fr auto;
        padding: 0 $padding $padding;
    }

    &__tickets-button {
        align-self: end;
        padding: 0.5rem;
    }

    &__date {
        display: inline-block;
    }

    &__time {
        display: inline-block;
        padding-left: $padding;
    }
}
</style>
