import gql from 'graphql-tag'

export const MY_NOTIFICATIONS_COUNT = gql`
    query MyNotificationsCount {
        myNotificationsCount
    }
`
export const MY_NOTIFICATIONS = gql`
    query MyNotifications(
        $sort: String
        $limit: Int
        $start: Int
        $where: JSON
        $profileType: String
    ) {
        myNotifications(
            sort: $sort
            limit: $limit
            start: $start
            where: $where
            profileType: $profileType
        ) {
            id
            createdAt
            updatedAt
            context
            isRead
            reciever
            notify {
                id
                username
                fullname
                fan {
                    id
                    name
                    media {
                        id
                        profile {
                            id
                            url
                        }
                    }
                }
                status
            }
            sender {
                id
                name
                type
                media {
                    id
                    profile {
                        id
                        url
                    }
                }
                avatar @client
                description
            }
        }
    }
`
