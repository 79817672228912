<template>
    <div v-if="isVisible" class="prompt text-beige bg-primary" @click="handleClick">
        <img :src="imgUrl" class="prompt__portrait" />
        <h3 class="prompt__text">
            {{ text }}
        </h3>
        <img
            class="prompt__close-icon"
            src="@/assets/cross_beige.svg"
            @click.stop="isVisible = false"
        />
    </div>
</template>

<script>
const defaultMap = new Map([
    ['Fan', require('@/assets/bird_portrait_fan.svg')],
    ['Artist', require('@/assets/bird_portrait_artist.svg')],
    ['Venue', require('@/assets/bird_portrait_venue.svg')]
])
export default {
    name: 'Prompt',
    inject: ['switchToFanProfile', 'switchToArtistProfile', 'switchToVenueProfile'],
    props: {
        profile: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isVisible: true
        }
    },
    computed: {
        text() {
            const type = this.profile.type.toLowerCase()
            return this.isPending ? `Create your ${type} profile` : `Finish your ${type} profile`
        },
        isPending() {
            return this.profile.status === 'PENDING'
        },
        imgUrl() {
            return defaultMap.get(this.profile.type)
        }
    },
    methods: {
        async handleClick() {
            if (this.profile.type === 'Fan') {
                await this.switchToFanProfile()
                this.$router.push('/profile/me/edit')
            } else if (this.profile.type === 'Artist') {
                await this.switchToArtistProfile()
                this.$router.push('/profile/me/edit')
            } else if (this.profile.type === 'Venue') {
                await this.switchToVenueProfile()
                this.$router.push('/profile/me/edit')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.prompt {
    display: grid;
    padding: 0.5rem;
    grid-template-columns: auto 1fr auto;
    border-radius: 5px;
    column-gap: 1rem;
    box-shadow: 1rem 1rem 4rem 1rem rgba(0, 0, 0, 0.3);
    &__text {
        align-self: center;
    }
    &__close-icon {
        padding: 0.5rem 0.5rem 0 0;
        transition: transform 0.3s ease;
        &:hover {
            transform: scale(1.4);
            cursor: pointer;
        }
    }
    &__portrait {
        width: 60px;
    }
}
</style>
