<template>
    <router-link :to="`/profile/${profileId}`">
        {{ getLabel }}
    </router-link>
</template>

<script>
export default {
    name: 'ProfileLink',
    inject: ['getMyCurrentProfile'],
    props: {
        profile: {
            type: Object,
            required: true,
        },
        label: {
            type: String,
            default: null,
        },
    },
    computed: {
        profileId() {
            return this.getMyCurrentProfile().id === this.profile.id
                ? 'me'
                : this.profile.__typename.toLowerCase() + '/' + this.profile.id
        },
        getLabel() {
            return this.label ? this.label : this.profile.name
        },
    },
}
</script>
